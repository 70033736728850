@tailwind base;
@tailwind components;
@tailwind utilities;

@layer reset, base, tokens, recipes, utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

:root, * {
  text-rendering: optimizeLegibility !important;
  text-rendering: geometricPrecision !important;
}

html, body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-start-rgb));
  height: 100dvh;
  margin: 0;
  scroll-behavior: smooth;
}


body {
  display: flex;
  flex: 1;
  flex-direction: column;
}
 * {
  min-width: 0;
  /* min-height: max-content; */
 }
